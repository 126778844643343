// Generated by Framer (508aa67)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["DuoFe3qox"];

const serializationHash = "framer-h0GV1"

const variantClassNames = {DuoFe3qox: "framer-v-meau9g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "DuoFe3qox", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-meau9g", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DuoFe3qox"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1geggjq"} data-framer-name={"Background"} layout={"position"} layoutDependency={layoutDependency} layoutId={"oKfobixBb"} opacity={1} radius={30} style={{backgroundColor: "var(--token-fcfba53b-60c4-4dca-9352-0e4845d40232, rgb(242, 242, 242))", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 1108 1311\"></svg>"} svgContentId={3974569016} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-h0GV1.framer-1w873to, .framer-h0GV1 .framer-1w873to { display: block; }", ".framer-h0GV1.framer-meau9g { height: 1311px; overflow: hidden; position: relative; width: 1108px; }", ".framer-h0GV1 .framer-1geggjq { flex: none; height: 1311px; left: calc(50.00000000000002% - 1108px / 2); position: absolute; top: calc(49.96186117467584% - 1311px / 2); width: 1108px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1311
 * @framerIntrinsicWidth 1108
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjMChkfhoD: React.ComponentType<Props> = withCSS(Component, css, "framer-h0GV1") as typeof Component;
export default FramerjMChkfhoD;

FramerjMChkfhoD.displayName = "Card";

FramerjMChkfhoD.defaultProps = {height: 1311, width: 1108};

addFonts(FramerjMChkfhoD, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})